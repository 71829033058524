<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" id="expert">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6"></div>
              <div class="col-md-6">
                <div class="all-tabs">
                  <div
                    :class="activeTab == 'all' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('all');
                      currentPage = 1;
                    "
                    class="mx-2 opt"
                  >
                    All
                  </div>
                  |
                  <div
                    :class="activeTab == 'active' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('active');
                      currentPage = 1;
                    "
                    class="mx-2 opt"
                  >
                    Active
                  </div>
                  |
                  <div
                    :class="activeTab == 'in_active' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('in_active');
                      currentPage = 1;
                    "
                    class="mx-2 opt"
                  >
                    Inactive
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :items="tableData.data"
                :per-page="0"
                :current-page="currentPage"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-slot:cell(status)="row">
                  <div
                    class="= badge badge-success text-capitalize"
                    v-if="row.item.status"
                  >
                    active
                  </div>
                  <div class="= badge badge-danger text-capitalize" v-else>
                    inactive
                  </div>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import WalletTransactionMixin from "../../../mixins/ModuleJs/wallet-transaction";

export default {
  mixins: [MixinRequest, WalletTransactionMixin],
  data() {
    return {
      title: "Wallet Transactions",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>
