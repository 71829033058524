import { indonesia_payment } from "../../js/path";
import axios from "axios";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      pageNo: null,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      currentPage: 1,
      activeTab: "all",
      params: "",
      key: 0,
      serachBy: null,
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "wallet_id",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "transaction_type",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "balance",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "previous_balance",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "details",
          class: "c_touchpt_header",
          visible: true,
        },
      ],
    };
  },
  methods: {
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData();
      } else {
        this.fetchData();
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData();
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(txt) {
      this.$store.commit("loader/updateStatus", true);
      if (txt == "active") {
        this.activeTab = txt;
      } else if (txt == "in_active") {
        this.activeTab = txt;
      } else if (txt == "all") {
        this.activeTab = txt;
      }
      let request = `${indonesia_payment?.getWalletTransaction}?page=${
        this.currentPage ?? 1
      }&q=${this.$route.params.id ?? ""}`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            this.tableData = res?.response;
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
        this.pageNo = this.currentPage;
      },
    },
  },

  created() {
    this.fetchData();
  },
};
